import '../App.css';
import React, { Component } from 'react';
import Story from "../components/story";
import Ticker from '../components/ticker';
import Ticker2 from '../components/ticker2';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Footer from '../components/footer';
import logo from '../assets/logo.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import About from '../components/about';
import Introduce from '../components/introduce';
import FAQ from '../components/faq';
import nft1 from '../assets/1.jpg';
import nft2 from '../assets/2.jpg';
import nft3 from '../assets/3.jpg';
import nft4 from '../assets/4.jpg';
import GalleryLaunchApp from '../components/galleryLaunchApp';

import basketBall from '../assets/basketball.png';
import mm from '../assets/MetaMask_Fox.svg.png';
import wc from '../assets/wc.png';
import $ from "jquery";

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let token_contract;
let free_mint_status;
let freeMintCost;
let freeMintPerWallet;
let tokenEligibleBalance;
let freeMintLimit;
let tokenBalance;
let freeMinted;

$(function () {
	const scrollEffect = $('.conT2Main');
	const inn1 = $('#inn1');
	const inn2 = $('#inn2');
	const inn3 = $('#inn3');
	const inn4 = $('#inn4');
	const inn5 = $('#inn5');
	const in9 = $('#in9');
	const in10 = $('#in10');
	const in11 = $('#in11');
	const in12 = $('#in12');
	const in13 = $('#in13');
	const in14 = $('#in14');
	const in15 = $('#in15');
	const in16 = $('#in16');


	$(window).on('scroll', function () {
		const containerTop = scrollEffect.offset().top;
		const containerBottom = containerTop + scrollEffect.outerHeight();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + $(window).height();

		if (windowBottom > containerTop) {
			inn1.addClass('inn1');
			inn2.addClass('inn2');
			inn3.addClass('inn3');
			inn4.addClass('inn4');
			inn5.addClass('inn5');
			in9.addClass('in9');
			in10.addClass('in10');
			in11.addClass('in11');
			in12.addClass('in12');
			in13.addClass('in13');
			in14.addClass('in14');
			in15.addClass('in15');
			in16.addClass('in16');

		} else {
			inn1.removeClass('inn1');
			inn2.removeClass('inn2');
			inn3.removeClass('inn3');
			inn4.removeClass('inn4');
			inn5.removeClass('inn5');
			in9.removeClass('in9');
			in10.removeClass('in10');
			in11.removeClass('in11');
			in12.removeClass('in12');
			in13.removeClass('in13');
			in14.removeClass('in14');
			in15.removeClass('in15');
			in16.removeClass('in16');
		}


		console.log("containerTop :" + containerTop);
		console.log("containerBottom :" + containerBottom);
		console.log("windowTop :" + windowTop);
		console.log("windowBottom :" + windowBottom);


	});
});

class Home extends Component {

	state = {
		_navbarOpen: 0,

	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<div class="txt">HYBRIDS</div>
						<div class="loading-area">
							<div class="loader">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<div class="cont">

						{this.state._navbarOpen < 1 ?
							(<div class="miniBarMain">
								<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logo" src={logo} /></div></Link>

								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">
								<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logo" src={logo} /></div></Link>

								<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}
						<Link to="gallery" spy={true} smooth={true} duration={550}>
							<div class="headers">

								<div class="h1">

									<div class="topLogo">

										<div ><img class="logoMobile" src={logo} /></div>

										<div class="iconsMob">
											<div class="connect2Mobile">
												<button class="connectBtn" >Connect Wallet</button>
											</div>
										</div>
									</div>

									<div class="logoDiv"><img class="logo" src={logo} /></div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
										<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
									</div>

									<div class="right">
										<Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}><button class="connectBtn">
											Explore
										</button>
										</Link>
									</div>

								</div>

							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
									<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
									<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
									<div class="connect2btns">
										<Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}><button class="connectBtn">
											Explore
										</button>
										</Link>
									</div>
								</div>) : null}
						</Link>


					</div>

					<Element name="story">
						<Introduce />
					</Element>

					<Element name="about">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="conT2Main">
								<div class="conT2">
									<span id="inn1">W</span>
									<span id="inn2">R</span>
									<span id="inn3">A</span>
									<span id="inn4">P</span>
									<span>&nbsp;</span>
									<span id="inn5">&</span>
									<span>&nbsp;</span>
									<span id="in9">M</span>
									<span id="in10">I</span>
									<span id="in11">N</span>
									<span id="in12">T</span>
									<span>&nbsp;</span>
									<span id="in13">A</span>
									<span id="in14">R</span>
									<span id="in15">E</span>
									<span id="in16">A</span>
								</div>
							</div>

							{this.state.walletAddress !== '' ?

								(<div class="mintDiv">

									<div class="slideImg">
										<div class="animation-container">
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft2} alt="Image 2" />
											<img class="image" src={nft3} alt="Image 3" />
											<img class="image" src={nft4} alt="Image 4" />
											<img class="image" src={nft3} alt="Image 5" />
											<img class="image" src={nft2} alt="Image 1" />
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft4} alt="Image 2" />
											<img class="image" src={nft1} alt="Image 3" />
											<img class="image" src={nft2} alt="Image 4" />
											<img class="image" src={nft3} alt="Image 5" />
											<img class="image" src={nft4} alt="Image 5" />
										</div>

									</div>
									<div class="mintBox">

										<div class="mintBox2">
											<div class="mintCon1">Be part of the creative revolution and mint your own unique NFTs, and receive ETH rewards, simply by wrapping your $HYBRID erc20 tokens!</div>

											<div class="basketBallDiv"><img class="basketBall" src={basketBall}></img></div>
											<button class="mintBtn" onClick={this.walletConnect}>CONNECT WALLET</button>
										</div>
									</div>

									<div class="slideImg2">
										<div class="animation-container">
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft2} alt="Image 2" />
											<img class="image" src={nft3} alt="Image 3" />
											<img class="image" src={nft4} alt="Image 4" />
											<img class="image" src={nft3} alt="Image 5" />
											<img class="image" src={nft2} alt="Image 1" />
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft4} alt="Image 2" />
											<img class="image" src={nft1} alt="Image 3" />
											<img class="image" src={nft2} alt="Image 4" />
											<img class="image" src={nft3} alt="Image 5" />
											<img class="image" src={nft4} alt="Image 5" />
										</div>

									</div>

								</div>) : (
									<div class="mintDiv">
										{(Number(this.state._tokenBalance) > Number(this.state._tokenEligibleBalance)) && (Number(this.state._freeMinted) < 1) ? (
											<div class="mintBox--1">
												<div class="mintBox2--2">
													<div class="totalSupply2">FREE MINTED</div>
													<div class="totalSupply">{this.state._freeMintLimit} / 500</div>
													{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

														(<div class="price"><div>Limit Reached!</div></div>) :
														(<div class="price"><div>🎉Congratulations!🎉 <br />You are rewarded for being in our community</div></div>)}
													<div class="minting_count_button">
													</div>

													<form onSubmit={this.freeMint}>
														<button class="mintBtn--2" type="submit">
															FREE MINT</button>
													</form>

													<div>

														{this.state.statusErrorFree ? (
															<div class="errorMessage">
																<div >Sorry, something went wrong please try again later</div>
															</div>)
															: null}

														{this.state.statusLoadingFree ? (
															<div class="loadingContainer">
																<div>
																	<div class="loadingText">Minting your NFT</div>
																</div>
															</div>)
															: null}

														{this.state.successFree ? (

															<div class="success-message">
																<h2>Successful NFT Minting!</h2>
																<p>Your NFT has been successfully minted.</p>
															</div>)
															: null}

													</div>
												</div>
											</div>
										) :
											(<div>
												<div class="slideImg2">
													<div class="animation-container">
														<img class="image" src={nft2} alt="Image 2" />
														<img class="image" src={nft3} alt="Image 3" />
														<img class="image" src={nft4} alt="Image 4" />
														<img class="image" src={nft3} alt="Image 5" />
														<img class="image" src={nft2} alt="Image 1" />
														<img class="image" src={nft1} alt="Image 1" />
														<img class="image" src={nft4} alt="Image 2" />
														<img class="image" src={nft1} alt="Image 3" />
														<img class="image" src={nft2} alt="Image 4" />
														<img class="image" src={nft3} alt="Image 5" />
														<img class="image" src={nft4} alt="Image 5" />
													</div>

												</div>

												<div class="slideImg">
													<div class="animation-container">
														<img class="image" src={nft2} alt="Image 2" />
														<img class="image" src={nft3} alt="Image 3" />
														<img class="image" src={nft4} alt="Image 4" />
														<img class="image" src={nft3} alt="Image 5" />
														<img class="image" src={nft2} alt="Image 1" />
														<img class="image" src={nft1} alt="Image 1" />
														<img class="image" src={nft4} alt="Image 2" />
														<img class="image" src={nft1} alt="Image 3" />
														<img class="image" src={nft2} alt="Image 4" />
														<img class="image" src={nft3} alt="Image 5" />
														<img class="image" src={nft4} alt="Image 5" />
													</div>

												</div>
											</div>)}

										<div class="mintBox">
											<div class="mintBox2">
												<div class="totalSupply2">TOTAL SUPPLY</div>
												<div class="totalSupply">{this.state.totalSupply} / 2000</div>
												{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

													(<div class="price"><div>Limit Reached!</div></div>) :
													(<div class="price"><div>NFT PRICE {this.state._cost / 1000000000000000000} Eth</div></div>)}

												<div class="minting_count_button">
													<div class="center">
														<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
													</div>

													<div>
														<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
													</div>

													<div class="center">
														<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
													</div>
												</div>

												<form onSubmit={this.onSubmit2}>
													<button class="mintBtn" type="submit">
														MINT HERE</button>
												</form>

												<div>

													{this.state.statusError ? (
														<div class="errorMessage">
															<div >Sorry, something went wrong please try again later</div>
														</div>)
														: null}

													{this.state.statusLoading ? (
														<div class="loadingContainer">
															<div>
																<div class="loadingText">Minting your NFT</div>
															</div>
														</div>)
														: null}

													{this.state.success ? (

														<div class="success-message">
															<h2>Successful NFT Minting!</h2>
															<p>Your NFT has been successfully minted.</p>
														</div>)
														: null}

												</div>
											</div>
										</div>
									</div>
								)}

						</div>

					</Element>

					<Ticker />

					<GalleryLaunchApp />

					<Element name="faq">
						<FAQ />
					</Element>

					<About />

					<Ticker2 />

					<Footer />

				</div>

			</div>)
	}
}

export default Home;
