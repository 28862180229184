import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import book from '../assets/book.png';
import $ from "jquery";

var textAnimated = 0;

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

$(function () {
    const scrollEffect = $('.storyConDiv');
 
    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            scrollEffect.addClass('animate');

            if(textAnimated == 0 ){

            $(function () {
                textAnimated = 1;
                $('#swift-up-text').each(function() {
                    var words = $(this).text().split(' ');
                    $(this).empty();
                
                    $.each(words, function(index) {
                        words[index] = '<span><i>' + words[index] + '</i></span>';
                    });
                
                    $(this).html(words.join(' '));
                
                    var children = $('span > i', this);
                    children.each(function(index) {
                        $(this).css('animation-delay', index * 0.2 + 's');
                    });
                });
                })
            }
        } else {
            scrollEffect.removeClass('animate');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});

$(function () {
    const scrollEffect = $('.storyConP');

    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            scrollEffect.addClass('animate2');
        } else {
            scrollEffect.removeClass('animate2');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});


$(function () {

    const cursor = $('.cursor__circle');
    const hover = $('a');

    const onMouseMove = (e) => {
        cursor.stop().animate({
            left: e.pageX - 16,
            top: e.pageY - 16,
        }, 400);
    };

    const onMouseHover = () => {
        cursor.stop().animate({
            scale: 3,
        }, 400);
    };

    const onMouseHoverOut = () => {
        cursor.stop().animate({
            scale: 1,
        }, 400);
    };

    const attachEventListeners = () => {
        $('body').on('mousemove', onMouseMove);
        hover.on('mouseenter', onMouseHover);
        hover.on('mouseleave', onMouseHoverOut);
    };

    const detachEventListeners = () => {
        $('body').off('mousemove', onMouseMove);
        hover.off('mouseenter', onMouseHover);
        hover.off('mouseleave', onMouseHoverOut);
    };
});


$(window).on('mousemove', function (e) {
    $('html').css('--x', (e.clientX + window.scrollX) + 'px');
    $('html').css('--y', (e.clientY + window.scrollY) + 'px');
});


class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="about">

                    <div class="storyCon">
                        <div class="storyConDiv" id="swift-up-text">EXPLORE NFT COLLECTION</div>
                        <div class="storyConP">UNLEASH THE POWER OF DIGITAL ART AND ETHEREUM REWARDS</div>
                     </div>
     
                    <img class="book" src={book} />
                    <span class="cursor"></span>
                </div>

            </div>
        )
    }
}

export default Story;

