import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import link from '../assets/external-link.png';
import nft1 from '../assets/6.png';
import vr from '../assets/vr.png';
import twitter from '../assets/twitter (1).png';
import tg from '../assets/telegram.png';
import $ from "jquery";

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

$(function () {
    const scrollEffect = $('.tableH');
    const in20 = $('#in20');
    const in21 = $('#in21');
    const in22 = $('#in22');
    const in23 = $('#in23');
    const in24 = $('#in24');
    const in25 = $('#in25');
    const in26 = $('#in26');
    const in27 = $('#in27');
    const in28 = $('#in28');
    const in29 = $('#in29');
    const in30 = $('#in30');
    const in31 = $('#in31');
    const in32 = $('#in32');
    const in33 = $('#in33');
    const in34 = $('#in34');


    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            in20.addClass('in20');
            in21.addClass('in21');
            in22.addClass('in22');
            in23.addClass('in23');
            in24.addClass('in24');
            in25.addClass('in25');
            in26.addClass('in26');
            in27.addClass('in27');
            in28.addClass('in28');
            in29.addClass('in29');
            in30.addClass('in30');
            in31.addClass('in31');
            in32.addClass('in32');
            in33.addClass('in33');
            in34.addClass('in34');
        } else {
            in20.removeClass('in20');
            in21.removeClass('in21');
            in22.removeClass('in22');
            in23.removeClass('in23');
            in24.removeClass('in24');
            in25.removeClass('in25');
            in26.removeClass('in26');
            in27.removeClass('in27');
            in28.removeClass('in28');
            in29.removeClass('in29');
            in30.removeClass('in30');
            in31.removeClass('in31');
            in32.removeClass('in32');
            in33.removeClass('in33');
            in34.removeClass('in34');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});

$(function () {
    const scrollEffect = $('.tableH-2');
    const in35 = $('#in35');
    const in36 = $('#in36');
    const in37 = $('#in37');
    const in38 = $('#in38');
    const in39 = $('#in39');
    const in40 = $('#in40');
    const in41 = $('#in41');
    const in42 = $('#in42');
    const in43 = $('#in43');
    const in44 = $('#in44');
    const in45 = $('#in45');
    const in46 = $('#in46');
    const in47 = $('#in47');
    const in48 = $('#in48');
    const in49 = $('#in49');
    const in50 = $('#in50');
    const in51 = $('#in51');
    const in52 = $('#in52');
    const in52N = $('#in52N');
 

    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            in35.addClass('in35');
            in36.addClass('in36');
            in37.addClass('in37');
            in38.addClass('in38');
            in39.addClass('in39');
            in40.addClass('in40');
            in41.addClass('in41');
            in42.addClass('in42');
            in43.addClass('in43');
            in44.addClass('in44');
            in45.addClass('in45');
            in46.addClass('in46');
            in47.addClass('in47');
            in48.addClass('in48');
            in49.addClass('in49');
            in50.addClass('in50');
            in51.addClass('in51');
            in52.addClass('in52');
            in52N.addClass('in52N');
        } else {
            in35.removeClass('in35');
            in36.removeClass('in36');
            in37.removeClass('in37');
            in38.removeClass('in38');
            in39.removeClass('in39');
            in40.removeClass('in40');
            in41.removeClass('in41');
            in42.removeClass('in42');
            in43.removeClass('in43');
            in44.removeClass('in44');
            in45.removeClass('in45');
            in46.removeClass('in46');
            in47.removeClass('in47');
            in48.removeClass('in48');
            in49.removeClass('in49');
            in50.removeClass('in50');
            in51.removeClass('in51');
            in52.removeClass('in52');
            in52N.removeClass('in52N');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});

const buy = () => {
    window.open("https://app.uniswap.org/tokens/ethereum/0xee092c25a430ab0d0aea39831ac8f62f2669a70f");
}

class GalleryLaunchApp extends Component {

    render() {
        return (

            <div class="main2">

                <div class="table2Main">

                    <div class="table1">
                        <div class="table1-1">
                            <div class="tableH">
                                <span id="in20">H</span>
                                <span id="in21">Y</span>
                                <span id="in22">B</span>
                                <span id="in23">R</span>
                                <span id="in24">I</span>
                                <span id="in25">D</span>
                                <span id="in27">S</span>

                                <br/>

                                <span id="in28">G</span>
                                <span id="in29">A</span>
                                <span id="in30">L</span>
                                <span id="in31">L</span>
                                <span id="in32">E</span>
                                <span id="in33">R</span>
                                <span id="in34">Y</span>
                            </div>
                            <p class="tableH2">Explore the exciting world of the Hybrids!</p>


                            {/*<button class="tableBtn">VIEW THE GALLERY <img src={link} /></button>*/}

                            
                       <div className='socialIcons2'>
                        <img src={twitter}/>
                        <img src={tg}/>
                       </div>

                        </div>

                       <img class="tableNFT1-" src={nft1}/>

                    </div>

                    <div class="table2">
                        <div class="table1-1">
                             <div class="tableH-2">
                                <span id="in37">H</span>
                                <span id="in38">Y</span>
                                <span id="in39">B</span>
                                <span id="in40">R</span>
                                <span id="in41">I</span>
                                <span id="in42">D</span>
                                <span id="in44">S</span>
                                <br/>
                                <span id="in45">C</span>
                                <span id="in46">O</span>
                                <span id="in47">M</span>
                                <span id="in48">M</span>
                                <span id="in49">U</span>
                                <span id="in50">N</span>
                                <span id="in51">I</span>
                                <span id="in52">T</span>
                                <span id="in52N">Y</span>
                              </div>
                            <p class="tableH2">Become a Hybrid and join the next erc20 to erc721 revolution.</p>
                            {/*<button class="tableBtn">VIEW THE GALLERY <img src={link} /></button>*/}

                            
                       <div className='socialIcons2'>
                        <img src={twitter}/>
                        <img src={tg}/>
                       </div>
                        </div>
                        <img class="tableNFT1-" src={vr}/>

                    </div>
                </div>

            </div>
        )
    }
}

export default GalleryLaunchApp;

