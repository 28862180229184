import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Ticker extends Component {


    render() {

        return (

            <div class="ticker-wrap2">
            <div class="ticker2">
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
              <div class="ticker__item2">EXPLORE THE POTENTIAL OF WEB3 WITH HYBRIDS</div>
            </div>
            </div>)
    }
}

export default Ticker;