import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';
import Ticker from './ticker';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
import instagram from '../assets/instagram.png';
import car from '../assets/car.png';

const insta = () => {
    window.open("https://www.instagram.com/y00punks/");
}

const tweet = () => {
    window.open("https://twitter.com/y00Punks");
}

const tg = () => {
    window.open("#");
}

const element = () => {
    window.open("#");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
  
                <div class="footer">
                    <div>
                  <div class="follow">FOLLOW US ON</div>  
                 <div class="socialImg">
                    <img onClick={tweet} src={twitter}/>    
                    <img src={discord}/>    
                    <img onClick={insta} src={instagram}/>    
                </div> 
                </div>
                <div class="footerH">HYBRIDS</div>
                </div>
                <div class="copyright">Copyright © 2023 HYBRIDS. All Rights Reserved</div>

             </div>
        )
    }
}

export default Footer;

